import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import _ from 'lodash';
import { openToastError } from 'src/use-cases/toast/useToast';
import { fetchAuthSession } from 'aws-amplify/auth';
import * as Sentry from '@sentry/react';

export type AttachInterceptorFunction = (axiosInstance: AxiosInstance) => void;

const acceptanceErrorMessages = ['No active draft exists', 'Cannot find Invoice OCR Message'];

export const attachCommonErrorInterceptor: AttachInterceptorFunction = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const isValidityPeriodError = error?.response?.data?.message?.search('validity period must not overlap with existing contracts') === 0;
      if (error?.response?.status === 400 && isValidityPeriodError) {
        throw new Error(error?.response?.data?.message);
      } else {
        openToastError(error?.response?.data?.message ? error?.response?.data : error);
      }
    }
  );
};

export const attachStatusCodeErrorInterceptor: AttachInterceptorFunction = (axiosInstance) => {
  axiosInstance.interceptors.response.use((response) => {
    const isGetDraftError = acceptanceErrorMessages.some((errorMessage) => response?.data?.message?.startsWith(errorMessage));
    if (response?.data?.statusCode && response.data.statusCode !== 200 && !isGetDraftError) {
      openToastError(response.data);
      // This will stop the next interceptor (attachCommonErrorInterceptor) from running
      // And throw the error to the catch block of the caller

      try {
        const { meta } = response.config;

        const data = {
          request: { ...meta?.request, body: JSON.stringify(meta?.request?.body), method: meta?.request?.method?.toUpperCase() },
          response: { message: response?.data?.message, statusCode: response?.data?.statusCode },
        };

        const message = `${meta?.request?.method?.toUpperCase()} [${meta?.request?.url}] [${response?.data?.statusCode}]`;

        // Manual breadcumbs
        // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/breadcrumbs/#manual-breadcrumbs
        Sentry.addBreadcrumb({
          type: 'error',
          category: 'api error',
          level: 'error',
          message,
          data,
        });
      } catch (error) {
        // We do not want this to crash the app
        console.error('Error adding breadcrumb to Sentry', error);
      }

      throw new Error(response?.data?.message);
    }
    return response;
  });
};

export const attachAuthInterceptor: AttachInterceptorFunction = (axiosInstance) => {
  axiosInstance.interceptors.request.use(async (cfg: InternalAxiosRequestConfig) => {
    cfg.headers.Authorization = await getUserAccessToken();
    return cfg;
  });
};

export const attachMetaInterceptor: AttachInterceptorFunction = (axiosInstance) => {
  axiosInstance.interceptors.request.use(async (cfg) => {
    cfg.meta = { request: { body: cfg.data, url: cfg.url, method: cfg.method } };
    return cfg;
  });
};

// Wraps Auth.currentSession()
// In developement mode, it will try to get the token from localStorage (i.e: expired token is also ok)
// This is nice for working offline
const getUserAccessToken = async () => {
  try {
    const cognitoUser = await fetchAuthSession();
    return cognitoUser.tokens.accessToken.toString();
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      const accessTokenKey = _.range(0, localStorage.length)
        .map((i) => localStorage.key(i))
        .find((key) => key.endsWith('accessToken'));
      const localStorageToken = localStorage.getItem(accessTokenKey);
      if (localStorageToken) return localStorageToken;
    }
    throw err;
  }
};
