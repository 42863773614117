import { FC, useMemo } from 'react';
import format from 'date-fns/format';
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { beautifyEnum, capitalizeName } from 'src/utils/utility';
import IconMail from 'src/assets/images/icons/icon-mail-colored.svg';
import IconInfo from 'src/assets/images/icons/info-circle-grey.svg';
import IconChevron from 'src/assets/images/icons/chevron-right.svg';
import IconCompleted from 'src/assets/images/icons/upload-completed.svg';
import TransportMediumIcon from '../card/TransportMediumIcon';
import { INotificationCardProps } from './types';
import { useNotificationSelector } from './NotificationSelector';
import { NotificationCardStatus } from './CardStatus';

interface StyledTooltipProps extends MuiTooltipProps {
  className?: string;
}

// Customize the tooltip
const Tooltip = styled(({ className, ...props }: StyledTooltipProps) => <MuiTooltip {...props} classes={{ popper: className }} />)(() => ({
  zIndex: 999990,
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#202020!important',
    borderRadius: '8px!important',
    color: 'white',
  },
  '& .MuiTooltip-arrow': {
    color: '#202020!important',
  },
}));

export const NotificationCard: FC<INotificationCardProps> = ({ data }) => {
  const { setSelectedDetail } = useNotificationSelector();

  const invoiceProcessed = useMemo(
    () =>
      data?.notificationStatus === 'EMAIL_RECEIVED'
        ? data?.processingInvoices || 0
        : (data?.flaggedInvoices || 0) + (data?.errorInvoices || 0) + (data?.rejectedInvoices || 0) + (data?.approvedInvoices || 0),
    [data]
  );

  return (
    <button type='button' className='px-8 group' onClick={() => setSelectedDetail(data)}>
      <div className='px-20 py-16 rounded-8 group-hover:bg-[#F5F6FA] transition-all duration-300 flex items-center gap-24'>
        <div className='w-[44px] h-[44px] rounded-full bg-white border border-[#DFDFDF] flex items-center justify-center shadow-[0_2px_2px_0_rgba(0,0,0,0.06)]'>
          <img src={data?.notificationSourceType === 'EMAIL' ? IconMail : IconCompleted} alt='status' />
        </div>
        <div className='flex flex-col gap-4'>
          {data?.notificationStatus === 'EMAIL_RECEIVED' ? (
            <div className='w-[122px] h-[12px] rounded-[3px] bg-[#F5F6FA] my-4' />
          ) : (
            <div className='flex items-center gap-8'>
              <div className='flex items-center'>
                {data?.modalities?.map((item, index) => (
                  <div key={`notif-${data?.notificationId}-modality-${item}`} className={index > 0 ? '-ml-10' : ''}>
                    <TransportMediumIcon medium={item} iconColor='#9F9F9F' size='sm' />
                  </div>
                ))}
              </div>
              <strong className='text-16 font-bold'>
                {data?.serviceProviderCodes?.length > 1 ? (
                  <>{data?.serviceProviderCodes?.length} SERVICE PROVIDERS</>
                ) : (
                  <>{beautifyEnum(data?.serviceProviderCodes[0])?.toUpperCase()}</>
                )}
              </strong>
              <span className='w-4 h-4 rounded-full bg-[#D9D9D9]' />
              <span className='text-12 text-[#808080]'>{data?.modalities?.join(' & ')}</span>
            </div>
          )}
          <div className='flex items-center gap-4'>
            <span className='text-14 font-medium'>{invoiceProcessed} New PDF Files</span>
            <Tooltip
              placement='bottom'
              arrow
              title={
                <div className='flex flex-col py-8 px-10 gap-3'>
                  {data?.approvedInvoices > 0 && (
                    <div className='flex items-center gap-8'>
                      <div className='w-8 h-8 rounded-full bg-primary' />
                      <strong className='text-white text-12'>{data?.approvedInvoices} Ready for Payment</strong>
                    </div>
                  )}
                  {data?.flaggedInvoices > 0 && (
                    <div className='flex items-center gap-8'>
                      <div className='w-8 h-8 rounded-full bg-orange-30' />
                      <strong className='text-white text-12'>{data?.flaggedInvoices} Flagged</strong>
                    </div>
                  )}
                  {data?.rejectedInvoices > 0 && (
                    <div className='flex items-center gap-8'>
                      <div className='w-8 h-8 rounded-full bg-red-40' />
                      <strong className='text-white text-12'>{data?.rejectedInvoices} Rejected</strong>
                    </div>
                  )}
                  {data?.errorInvoices > 0 && (
                    <div className='flex items-center gap-8'>
                      <div className='w-8 h-8 rounded-full border border-red-40' />
                      <strong className='text-white text-12'>{data?.errorInvoices} Under Review</strong>
                    </div>
                  )}
                </div>
              }
            >
              <img src={IconInfo} alt='' />
            </Tooltip>
          </div>
          <p className='text-[11px] text-[#808080]'>
            {data?.notificationSourceType === 'EMAIL' ? 'Email received on' : 'Uploaded on'} {format(new Date(data?.uploadedOn), "dd-MMM-yyyy 'at' hh:mm a")}{' '}
            {data?.notificationSourceType === 'EMAIL' ? 'from' : 'by'}{' '}
            <strong className='font-medium text-neutral-80'>
              {data?.notificationSourceType === 'EMAIL'
                ? data?.uploadedBy // Show name as is for EMAIL
                : capitalizeName(data?.uploadedBy)}{' '}
              {/* Apply the utility for other cases */}
            </strong>
          </p>
        </div>

        <div className='ml-auto flex items-center gap-16'>
          <NotificationCardStatus status={data?.notificationStatus} />
          <img src={IconChevron} alt='' />
        </div>
      </div>
    </button>
  );
};
