import Button from 'src/presentations/components/atom/button';
import { LandingTopWidget } from 'src/presentations/components/organisms/landing';
import Thumbnail from 'src/assets/images/under-construction.png';

const UnderConstructionPage = () => (
  <div className='flex flex-col h-full'>
    <LandingTopWidget hideNotification />
    <div className='relative bg-neutral-10 flex-1 flex items-center justify-center py-24'>
      <div className='flex flex-col items-center justify-center text-center gap-36'>
        <img src={Thumbnail} alt='' className='w-[240px]' />
        <div>
          <h3 className='text-60 text-neutral-50 font-bold'>Under Construction</h3>
          <p className='mt-12 text-16'>
            Please come back later or contact our <br />
            Support Team if the problem persists.
          </p>
        </div>
        <div>
          <Button variant='primary' path='/'>
            Back to Home
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default UnderConstructionPage;
