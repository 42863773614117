import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { tFinancialService } from 'src/infra/services/tFinancialService';
import { cacheKeysUtil } from 'src/utils/cacheKeysUtil';
import {
  AnalyticSearchRequestDto,
  ChartResponseDto,
  AggregatedDto,
  ChartReportRequestDto,
  ChartReportDto,
  KeyMetricsResponseDto,
  FiltersRequestDto,
  FiltersDto,
} from 'src/generated/services/TFinancialApi';
import { addYears, addQuarters, addMonths, addWeeks, addDays, format, parse } from 'date-fns';
import { formatDate } from 'src/utils/date';
import { AxiosResponse } from 'axios';
import { TableTab } from './table/useTable';

export function convertEmptyToNull(obj) {
  for (const key in obj) {
    if (obj[key] === '') {
      obj[key] = null;
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      convertEmptyToNull(obj[key]);
    }
  }
}

export const useChart = (filter: AnalyticSearchRequestDto): UseQueryResult<ChartResponseDto> => {
  convertEmptyToNull(filter);

  return useQuery<ChartResponseDto>(
    cacheKeysUtil.getChart(filter),
    async (): Promise<ChartResponseDto> => {
      const response = await tFinancialService.api.searchChartData(filter);
      const { data } = response.data;
      const completeDates = fillMissingDates(data, filter.granularity);
      return completeDates;
    },
    {
      enabled: !!filter.currencyReference,
    }
  );
};

export const useKeyMetrics = (useType: 'DASHBOARD' | 'REPORT' | 'PERFORMANCE_ASSESSMENT', filter: FiltersRequestDto): UseQueryResult<KeyMetricsResponseDto> => {
  convertEmptyToNull(filter);

  return useQuery(
    cacheKeysUtil.getKeyMetrics(filter),
    async (): Promise<KeyMetricsResponseDto> => (await tFinancialService.api.getKeyMetricsWithFilter(useType, filter)).data.data,
    {
      enabled: !!filter.currencyReference,
    }
  );
};

export const useFilters = (useType: 'DASHBOARD' | 'REPORT' | 'PERFORMANCE_ASSESSMENT', filter: FiltersRequestDto): UseQueryResult<FiltersDto> => {
  convertEmptyToNull(filter);
  return useQuery(
    cacheKeysUtil.getAnalyticFilters(filter),
    async (): Promise<FiltersDto> => (await tFinancialService.api.getFilters(useType, filter)).data.data,
    { enabled: !!filter.currencyReference }
  );
};

export const sortAggregatedData = (data: AggregatedDto[], columns: string[], direction: number = -1): AggregatedDto[] => {
  // sort by column 1, then if column 1 is the same, sort by column 2, etc
  const sortedData = [...data];

  sortedData.sort((a, b) => {
    for (const column of columns) {
      if (a[column] < b[column]) {
        return direction * -1;
      }
      if (a[column] > b[column]) {
        return direction;
      }
    }
    return 0;
  });

  return sortedData;
};

export const fillMissingDates = (data: ChartResponseDto, granularity: string): ChartResponseDto => {
  const completeData = { ...data };
  let addUnit;
  let formatString;

  switch (granularity) {
    case 'YEARLY':
      addUnit = addYears;
      formatString = 'yyyy';
      break;
    case 'QUARTERLY':
      addUnit = addQuarters;
      formatString = "yyyy-'Q'Q";
      break;
    case 'MONTHLY':
      addUnit = addMonths;
      formatString = 'yyyy-MMM';
      break;
    case 'WEEKLY':
      addUnit = addWeeks;
      formatString = "RRRR-'W'II";
      break;
    case 'DAILY':
      addUnit = addDays;
      formatString = 'yyyy-MM-dd';
      break;
    default:
      return completeData;
  }

  if (completeData.aggregated.length < 1) {
    return completeData;
  }
  const startDate = parse(completeData.aggregated[0].aggregatedUnit, formatString, new Date());
  const endDate = parse(completeData.aggregated[completeData.aggregated.length - 1].aggregatedUnit, formatString, new Date());

  let currentDate = startDate;
  let index = 0;

  while (currentDate < endDate) {
    const formattedDate = format(currentDate, formatString);
    if (formattedDate !== completeData.aggregated[index].aggregatedUnit) {
      const newAggregated = {} as AggregatedDto;
      newAggregated.aggregatedUnit = formattedDate;
      completeData.aggregated.splice(index, 0, newAggregated);
    }
    index += 1;
    currentDate = addUnit(currentDate, 1);
  }

  return completeData;
};

export const useChartReports = (): UseQueryResult<ChartReportDto[]> =>
  useQuery(cacheKeysUtil.getChartReports(), async () => (await tFinancialService.api.getChartReports()).data.data);

export const useSaveReport = () => {
  const queryClient = useQueryClient();
  return useMutation(async (chartReportRequest: ChartReportRequestDto) => tFinancialService.api.createChartReport(chartReportRequest), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries(cacheKeysUtil.getChartReports())]);
    },
  });
};

export const useUpdateReport = () => {
  const queryClient = useQueryClient();
  return useMutation(async (chartReportRequest: ChartReportRequestDto) => tFinancialService.api.updateChartReport(chartReportRequest), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries(cacheKeysUtil.getChartReports())]);
    },
  });
};

export const useDeleteReport = () => {
  const queryClient = useQueryClient();
  return useMutation(async (chartReportId: string) => tFinancialService.api.deleteChartReport(chartReportId), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries(cacheKeysUtil.getChartReports())]);
    },
  });
};

type FileType = 'CSV' | 'EXCEL';
const downloadFunctions = {
  Shipments: tFinancialService.api.downloadShipmentData,
  Charges: tFinancialService.api.downloadInvoiceChargesData,
  Invoices: tFinancialService.api.downloadInvoiceData,
  SAVINGS: tFinancialService.api.getSavingsTableDataDownload,
  VOLUMETRIC: tFinancialService.api.getVolumetricTableDataDownload,
} as const;

const downloadFile = (response: AxiosResponse<void, any>, fileName: string, type: FileType) => {
  const url = window.URL.createObjectURL(new Blob([response.data as unknown as BlobPart]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${type === 'CSV' ? 'csv' : 'xlsx'}`);
  document.body.appendChild(link);
  link.click();
};
export const useDownloadTemplate = (tableTab: TableTab, name: string = 'default_name', formValues, pageSize: number = 10) => {
  const download = async (type: FileType) => {
    const safeName = name ? name.replace(/\s/g, '') : 'default_name';
    const fileName = `${safeName}_${formatDate(new Date())}_${tableTab.toLowerCase()}`;

    const selectedDownloadFunction = downloadFunctions[tableTab];

    if (selectedDownloadFunction) {
      let apiParams: Record<string, any> = { page: { pageNumber: 1, pageSize } };

      if (tableTab === 'SAVINGS' || tableTab === 'VOLUMETRIC') {
        apiParams = { ...apiParams, performanceAssessmentRequest: formValues };
      } else {
        // Report And Analytics Page
        apiParams = { ...apiParams, searchRequest: { ...formValues.filtersRequest } };
      }
      try {
        const response = await selectedDownloadFunction({ 'file-name': fileName, 'file-type': type }, apiParams, { format: 'blob' });

        downloadFile(response, fileName, type);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  return { download };
};
