import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserIssueRequest } from 'src/generated/services/TFinancialApi';
import { tFinancialService } from 'src/infra/services/tFinancialService';
import { cacheKeysUtil } from 'src/utils/cacheKeysUtil';

export const useCreateUserIssue = () => {
  const queryClient = useQueryClient();

  return useMutation(async (data: UserIssueRequest) => tFinancialService.api.createUserIssue(data), {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: async (_, args) => {
      await Promise.all([queryClient.invalidateQueries(cacheKeysUtil.invoices({}))]);
    },
  });
};
