import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { startCase, toLower } from 'lodash';

const { locale } = new Intl.NumberFormat().resolvedOptions();

// https://www.youtube.com/watch?v=re2JFITR7TI&ab_channel=ByteGrad
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const dateReviver = (key: string, value: any) => {
  let a;
  if (typeof value === 'string') {
    a = Date.parse(value);
    if (a) {
      return new Date(a);
    }
  }
  return value;
};
export const jsonParseWithDates = (str: string) => JSON.parse(str, dateReviver);

/**
 * @param amount
 * @param currency
 * @returns
 */
export const formatMoney = (amount: number, currency: string) => (amount ? `${currency} ${formatNumber(amount)}` : '');

/**
 * @param value
 * @returns
 */
export const formatNumber = (value: number) =>
  value
    ? value.toLocaleString(locale, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : '';

export const nFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);
  return item ? (num / item.value).toFixed(digits).replace(regexp, '').concat(item.symbol) : '0';
};

/**
 * @param value
 * @returns
 */
export const SimpleFormatNumber = (value?: number, isHideDecimal?: boolean) => {
  if (!value) return 0;
  if (value === 0) return 0;
  let options: Record<string, any> = {
    style: 'decimal',
  };

  if (isHideDecimal) {
    options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      ...options,
    };
  }
  return value.toLocaleString(locale, options);
};

export const byteConverter = (bytes: number, decimals: number = 2) => {
  const K_UNIT = 1024;
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  if (bytes === 0) {
    return '0 Byte';
  }
  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  const result = `${parseFloat((bytes / K_UNIT ** i).toFixed(decimals))} ${SIZES[i]}`;

  return result;
};

export const beautifyEnumWithTHC = (value: string) => {
  if (value === 'THC') {
    return value;
  }
  return beautifyEnum(value);
};

export const beautifyEnum = (name: string) =>
  name
    ? name
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase())
    : '';

export const booleanToYesNo = (value: boolean | string) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true' ? 'Yes' : 'No';
  }
  return value;
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

type DisplayOption = number | string | null | undefined;
export const displayValueOrFallback = (value: DisplayOption, fallback: string = '-'): string => {
  if (!value) {
    return fallback;
  }
  switch (typeof value) {
    case 'number':
      return formatNumber(value);
    case 'string':
      return value;
    default:
      return value;
  }
};

export function keepFileAlphanumeric(name: string) {
  const pattern = /[^0-9a-zA-Z.]/g;
  return name.replace(pattern, '');
}

export const capitalizeName = (name: string): string => startCase(toLower(name));
