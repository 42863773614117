/**
 * InputReason
 */

import { FieldLabel } from 'src/presentations/components/molecules/fields/FieldLabel';
import { FC } from 'react';
import { useFieldMaterialUIStyles } from 'src/presentations/components/molecules/fields/useFieldMaterialUIStyles';
import { TextFieldElement, TextFieldElementProps, useWatch, useFormContext, CheckboxButtonGroup, CheckboxButtonGroupProps } from 'react-hook-form-mui';
import { useDefaultProps } from '../useDefaultProps';

export const RhfMuiTextArea: FC<TextFieldElementProps> = (props) => {
  const { fullWidth = true, size = 'small', label, placeholder, children, isReadOnly, name, rows, ...rest } = { ...props, ...useDefaultProps(props) };
  const { classes, cx } = useFieldMaterialUIStyles();

  const value = useWatch({ name });
  const formContext = useFormContext();

  const handleTrimOnBlur = (event: any) => {
    formContext.setValue(name, event.target.textContent.trim());
  };

  return (
    <FieldLabel label={label}>
      <div className='relative'>
        {!isReadOnly && (
          <TextFieldElement
            onBlurCapture={handleTrimOnBlur}
            name={name}
            className={cx({ [classes.shortWidth]: !fullWidth }, classes.inputField)}
            multiline
            rows={rows || 6}
            fullWidth
            size={size}
            label={placeholder || 'Input Description'}
            placeholder={placeholder}
            {...rest}
          />
        )}
        {isReadOnly && value}
        {children}
      </div>
    </FieldLabel>
  );
};
interface IRhfMuiTextFieldProps extends TextFieldElementProps {
  hideFieldLabel?: boolean;
}

export const RhfMuiTextField: FC<IRhfMuiTextFieldProps> = (props) => {
  props = { ...props, ...useDefaultProps(props) };
  const { fullWidth = true, size = 'small', label, children, isReadOnly, name, hideFieldLabel, ...rest } = { ...props, ...useDefaultProps(props) };
  const { classes, cx } = useFieldMaterialUIStyles();
  const value = useWatch({ name });

  return (
    <FieldLabel label={hideFieldLabel ? null : label}>
      <div className='relative'>
        {!isReadOnly && (
          <TextFieldElement
            name={name}
            label={hideFieldLabel ? label : null}
            className={cx({ [classes.shortWidth]: !fullWidth }, classes.inputField)}
            fullWidth
            size={size}
            {...rest}
          />
        )}
        {isReadOnly && value}
        {children}
      </div>
    </FieldLabel>
  );
};

export const RhfMuiNumericField: FC<IRhfMuiTextFieldProps> = (props) => {
  props = { ...props, ...useDefaultProps(props) };
  const { fullWidth = true, size = 'small', label, children, isReadOnly, name, hideFieldLabel, ...rest } = { ...props, ...useDefaultProps(props) };
  const { classes, cx } = useFieldMaterialUIStyles();

  const value = useWatch({ name });

  return (
    <FieldLabel label={hideFieldLabel ? null : label}>
      <div className='relative'>
        {!isReadOnly && (
          <TextFieldElement
            className={cx({ [classes.shortWidth]: !fullWidth }, classes.inputField)}
            fullWidth
            size={size}
            name={name}
            inputProps={{ inputMode: 'decimal', min: '0', step: 'any' }}
            label={hideFieldLabel ? label : null}
            type='number'
            {...rest}
          />
        )}
        {isReadOnly && value}
        {children}
      </div>
    </FieldLabel>
  );
};

export const RhfMuiCheckboxButtonGroup: FC<CheckboxButtonGroupProps<any>> = (props) => {
  props = { ...props, ...useDefaultProps(props) };
  const { label, ...rest } = props;

  return (
    <FieldLabel label={label}>
      <div className='relative'>
        <CheckboxButtonGroup {...rest} />
      </div>
    </FieldLabel>
  );
};
