import { useState } from 'react';
import { useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Box, IconButton } from '@mui/material';
import { CustomTable } from 'src/presentations/components/molecules/table/Table';
import { useFormContext } from 'react-hook-form';
import IconEdit from 'src/assets/images/icons/edit-2.svg';
import { WeightChargeDto } from 'src/generated/services/TFinancialApi';
import { beautifyEnum, formatMoney } from 'src/utils/utility';
import { NoContractForm } from './types';
import { TableFieldNumber } from './TableFieldNumber';

const useChargesWeightColumns = (): MRT_ColumnDef<WeightChargeDto>[] => [
  {
    accessorKey: 'chargeName',
    header: 'Charge Name / Category / [min Wt/Vol - max Wt/Vol]',
    enableEditing: false,
    accessorFn: (row) => (
      <span className='capitalize'>
        {row?.chargeName ? beautifyEnum(row.chargeName) : '-'}
        {row?.chargeCategory ? ` / ${beautifyEnum(row.chargeCategory)}` : ' / -'}
        {row?.minimumChargeableWeight && row?.maximumChargeableWeight ? ` / [${row.minimumChargeableWeight} - ${row.maximumChargeableWeight}]` : ' / -'}
      </span>
    ),
  },
  {
    accessorKey: 'legType',
    header: 'Leg',
    enableEditing: false,
    accessorFn: (row) => <span className='capitalize'>{row?.legType ? beautifyEnum(row.legType) : '-'}</span>,
  },
];

export interface ITableChargesWeightProps {
  data: WeightChargeDto[];
  enableEditing?: boolean;
}

export const TableChargesWeight = ({ data, enableEditing = false }: ITableChargesWeightProps) => {
  const [priceRowIsEditable, setPriceRowIsEditable] = useState({});
  const [minRowIsEditable, setMinRowIsEditable] = useState({});

  const columns = useChargesWeightColumns();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setValue } = useFormContext<NoContractForm>();

  const table = useMaterialReactTable({
    data,
    enableRowNumbers: false,
    enableSorting: false,
    enablePinning: false,
    enableRowDragging: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableEditing,
    editDisplayMode: 'table',
    muiTableHeadProps: {
      sx: {
        '& .MuiTableCell-head': {
          '&:first-of-type': {
            width: '40px',
            textAlign: 'center',
          },
        },
      },
    },
    columns: [
      {
        id: 'rowNumber',
        header: 'No.',
        size: 10,
        enableEditing: false,
        Cell: ({ row }) => <div style={{ width: '40px', textAlign: 'center' }}>{row.index + 1}</div>,
        Header: () => <div>No.</div>,
      },
      ...columns,
      {
        header: 'Rate Card Price / Unit',
        enableEditing: ({ index }) => !!priceRowIsEditable[index],
        accessorKey: 'contractUnitPrice',
        accessorFn: (row) => <>{row?.unitPrice && row?.currency ? `${formatMoney(row.unitPrice, row.currency)} / ${row.chargeUnit || '-'}` : '-'}</>,
        Cell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {row?.original.currency} {row?.original?.unitPrice} {priceRowIsEditable[row.index]} / {row?.original?.chargeUnit}
            </span>
            {enableEditing && (
              <IconButton
                onClick={() => {
                  setPriceRowIsEditable((pre) => ({
                    ...pre,
                    [row.index]: priceRowIsEditable[row.index] ? !priceRowIsEditable[row.index] : true,
                  }));
                }}
              >
                <img src={IconEdit} alt='' className='w-24' />
              </IconButton>
            )}
          </Box>
        ),
        Edit: ({ row }) => (
          <TableFieldNumber
            name={`weightCharges.${row.index}.unitPrice`}
            startAdornment={row?.original?.currency}
            defaultValue={`${row?.original?.unitPrice}`}
            onCancel={() => {
              setPriceRowIsEditable((pre) => ({
                ...pre,
                [row.index]: false,
              }));
            }}
          />
        ),
      },
      {
        header: 'Rate Card Minimum Price',
        enableEditing: ({ index }) => !!minRowIsEditable[index],
        accessorKey: 'minimumPrice',
        accessorFn: (row) => <>{row?.minimumPrice && row?.currency ? formatMoney(row.minimumPrice, row.currency) : '-'}</>,
        Cell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {row?.original.currency} {row?.original?.minimumPrice} {minRowIsEditable[row.index]}
            </span>
            {enableEditing && (
              <IconButton
                onClick={() => {
                  setMinRowIsEditable((pre) => ({
                    ...pre,
                    [row.index]: minRowIsEditable[row.index] ? !minRowIsEditable[row.index] : true,
                  }));
                }}
              >
                <img src={IconEdit} alt='' className='w-24' />
              </IconButton>
            )}
          </Box>
        ),
        Edit: ({ row }) => (
          <TableFieldNumber
            name={`weightCharges.${row.index}.minimumPrice`}
            startAdornment={row?.original?.currency}
            defaultValue={`${row?.original?.minimumPrice}`}
            onCancel={() => {
              setMinRowIsEditable((pre) => ({
                ...pre,
                [row.index]: false,
              }));
            }}
          />
        ),
      },
    ],
  });
  return <CustomTable table={table} />;
};
