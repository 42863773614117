import { compressToURI } from 'lz-ts';
import { stringify } from 'superjson';
import { ReactNode } from 'react';
import { AnalyticSearchRequestDto } from 'src/generated/services/TFinancialApi';
import { CardStatDefault, CardStatType, ICardStatProps, IStatValueProps, StatPercentType } from './Stat/types';
import { IProviderModalityProps } from './types';
import { ChartViewType } from '../analytics/sidebar/types';

export const getProviderModalityCombinations = (filterConfigs: Record<string, any>, dataFilter): IProviderModalityProps[] => {
  const providers = filterConfigs?.filters?.shipmentFilters?.serviceProviderCode;
  const modalities = filterConfigs?.filters?.shipmentFilters?.modality;

  const combinations: IProviderModalityProps[] = [];

  if (providers && modalities) {
    providers?.forEach((provider: string) => {
      modalities?.forEach((modality: string) => {
        combinations.push({
          provider,
          modality,
        });
      });
    });
  } else if (dataFilter?.serviceProviders) {
    dataFilter?.serviceProviders?.forEach((item) => {
      combinations.push({
        provider: item?.serviceProviderCode,
        modality: item?.modality,
      });
    });
  }

  return combinations;
};

const getStatValueFromApi = (dataStats: Record<string, any>, statName: CardStatType, fieldName: string) => {
  if (dataStats && typeof dataStats[statName] !== 'undefined' && dataStats[statName] && typeof dataStats[statName][fieldName] !== 'undefined') {
    return dataStats[statName][fieldName];
  }
  return 0;
};

export const getSavingStatsList = (dataStats: Record<string, any>): ICardStatProps[] => {
  const statNames: CardStatType[] = [CardStatType.AMOUNT, CardStatType.SHIPMENTS, CardStatType.INVOICES, CardStatType.CHARGER_LINES];

  return statNames.map((name: CardStatType) => {
    const values: IStatValueProps[] = [];
    if (name === CardStatType.AMOUNT) {
      values.push(
        {
          label: 'Before Audit',
          value: getStatValueFromApi(dataStats, name, 'beforeAudit')?.afterTax ?? 0,
        },
        {
          label: 'After Audit',
          value: getStatValueFromApi(dataStats, name, 'afterAudit')?.afterTax ?? 0,
          percent: getStatValueFromApi(dataStats, name, 'amountPercentage'),
          percentType: StatPercentType.PRIMARY,
        },
        {
          label: 'Deviations',
          value: getStatValueFromApi(dataStats, name, 'deviations')?.afterTax ?? 0,
          percent: getStatValueFromApi(dataStats, name, 'deviationPercentage'),
          percentType: StatPercentType.DANGER,
        },
        {
          label: 'Savings',
          value: getStatValueFromApi(dataStats, name, 'savings')?.afterTax ?? 0,
          percent: getStatValueFromApi(dataStats, name, 'savingsPercentage'),
          percentType: StatPercentType.SUCCESS,
        }
      );
    } else {
      values.push(
        {
          label: 'Before Audit',
          value: getStatValueFromApi(dataStats, name, 'beforeAudit') ?? 0,
        },
        {
          label: 'After Audit',
          value: getStatValueFromApi(dataStats, name, 'afterAudit') ?? 0,
          percent: getStatValueFromApi(dataStats, name, 'amountPercentage'),
          percentType: StatPercentType.PRIMARY,
        },
        {
          label: 'With Deviations',
          value: getStatValueFromApi(dataStats, name, 'deviations') ?? 0,
          percent: getStatValueFromApi(dataStats, name, 'deviationPercentage'),
          percentType: StatPercentType.DANGER,
        },
        {
          label: 'Savings',
          value: getStatValueFromApi(dataStats, name, 'savings') ?? 0,
          percent: getStatValueFromApi(dataStats, name, 'savingsPercentage'),
          percentType: StatPercentType.SUCCESS,
        }
      );
    }

    return {
      type: name,
      values,
    } as ICardStatProps;
  });
};

export const getVolumetricStatsList = (dataStats: Record<string, any>, defaultUnit?: CardStatDefault): ICardStatProps[] => {
  const statNames: CardStatType[] = [
    CardStatType.ASKED_AMOUNT,
    CardStatType.GROSS_WEIGHT,
    CardStatType.CHARGEABLE_WEIGHT,
    CardStatType.VOLUME,
    CardStatType.CO2E_EMISSIONS,
  ];

  return statNames.map((name: CardStatType) => {
    const values = [];

    let unit: ReactNode = '';
    const currency = defaultUnit?.unitCurrency ?? 'USD';
    switch (name) {
      case CardStatType.ASKED_AMOUNT:
        unit = currency;
        break;
      case CardStatType.GROSS_WEIGHT:
      case CardStatType.CHARGEABLE_WEIGHT:
        unit = 'kg';
        break;
      case CardStatType.VOLUME:
        unit = 'm³';
        break;
      case CardStatType.CO2E_EMISSIONS:
        unit = 't';
        break;
      default:
        unit = currency;
        break;
    }

    if (name === CardStatType.ASKED_AMOUNT) {
      values.push(
        {
          label: 'Total Excl. Rejected',
          value: getStatValueFromApi(dataStats, name, 'totalValue')?.afterTax ?? 0,
        },
        {
          label: `${unit} per shipment`,
          value: getStatValueFromApi(dataStats, name, 'unitPerShipment')?.afterTax ?? 0,
        }
      );
    } else {
      values.push(
        {
          label: 'Total Excl. Rejected',
          value: getStatValueFromApi(dataStats, name, 'totalValue'),
        },
        {
          label: `${unit} per shipment`,
          value: getStatValueFromApi(dataStats, name, 'unitPerShipment'),
        },
        {
          label: name === 'totalCo2Emissions' ? 'Intensity' : `${currency} per ${unit}`,
          value: getStatValueFromApi(dataStats, name, 'pricePerUnit'),
        }
      );
    }

    return {
      type: name,
      values,
    } as ICardStatProps;
  });
};

export const getAnalyticsLink = (filterConfigs: Record<string, any>): string => {
  const filtersState = {
    filters: {
      selectedChartView: ChartViewType.TREND,
      formValues: {
        granularity: 'DAILY',
        ...filterConfigs,
      } as AnalyticSearchRequestDto,
    },
  };
  const hashValue = compressToURI(stringify(filtersState));
  return `/analytics?report=${hashValue}`;
};
