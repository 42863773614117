import { CustomTooltip } from '../../atom/customtooltip';

export const simplifyFileNames = (filePath: string) => {
  const lastDotIndex = filePath.lastIndexOf('.');

  // Extract filename and file extension based on the last dot
  const filename = filePath.slice(0, lastDotIndex);
  const fileExt = filePath.slice(lastDotIndex + 1);

  let newFilePath = filePath;
  let isSimplified = false;

  // Simplify the filename if it's too long
  if (filename.length > 40) {
    isSimplified = true;
    const firstName = filename.slice(0, 20);
    const lastName = filename.slice(-20);
    newFilePath = `${firstName}...${lastName}.${fileExt}`;
  }

  if (!isSimplified) return newFilePath;

  // Wrap in tooltip if the name was simplified
  return (
    <CustomTooltip title={filePath}>
      <div className='relative'>{newFilePath}</div>
    </CustomTooltip>
  );
};
