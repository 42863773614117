import { useCallback } from 'react';

import Button from 'src/presentations/components/atom/button';
import { useContactSelector } from './ContactSelector';
import { RhfMuiTextArea } from '../fields/rhf-mui-fields';

export const ModalContactForm = ({ isLoading }: { isLoading: boolean }) => {
  const { setIsShowContact, setInvoice } = useContactSelector();

  const onCloseModal = useCallback(() => {
    setIsShowContact(false);
    setInvoice(null);
  }, [setInvoice, setIsShowContact]);

  return (
    <>
      <div className='mt-24'>
        <RhfMuiTextArea name='message' placeholder='State your problem here' required parseError={(error) => error.message.toString()} />
      </div>
      <div className='mt-24 grid gap-16 grid-cols-2 grid-rows-1'>
        <Button variant='secondary' isFull onClick={onCloseModal} disabled={isLoading}>
          Cancel
        </Button>
        <Button type='submit' variant='primary' isFull isLoading={isLoading}>
          Submit
        </Button>
      </div>
    </>
  );
};
