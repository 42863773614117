import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { IContactInvoiceDto, IContactIssueDto } from './types';

export interface IContactSelectorProps {
  children: ReactNode;
}

export interface IContactContext {
  isShowContact: boolean;
  invoice: IContactInvoiceDto;
  issue: IContactIssueDto;
  setIsShowContact: (status: boolean) => void;
  setIssue: (issue: IContactIssueDto) => void;
  setInvoice: (invoice: IContactInvoiceDto) => void;
}

export const ContactSelectorContext = createContext<IContactContext>(null);

export const ContactSelector = ({ children }: IContactSelectorProps) => {
  const [isShowContact, setIsShowContact] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<IContactInvoiceDto>(null);
  const [issue, setIssue] = useState<IContactIssueDto>(null);

  const value = useMemo(
    () => ({
      isShowContact,
      invoice,
      issue,
      setIsShowContact,
      setInvoice,
      setIssue,
    }),
    [invoice, isShowContact, issue]
  );

  return (
    <>
      <ContactSelectorContext.Provider value={value}>{children}</ContactSelectorContext.Provider>
    </>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export function useContactSelector() {
  const context = useContext(ContactSelectorContext);

  if (!context) {
    throw new Error(`Contact selector compound components cannot be rendered outside the Contact Selector component`);
  }
  return context;
}
