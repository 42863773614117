import { FC } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { nFormatter, SimpleFormatNumber } from 'src/utils/utility';
import { CustomTooltip } from 'src/presentations/components/atom/customtooltip';
import { IStatDataProps, StatPercentType } from './types';

export const StatData: FC<IStatDataProps> = (props) => {
  const { label, value, percent, className, isHaveBorder, percentType = StatPercentType.PRIMARY, size = 'lg' } = props;

  const { watch } = useFormContext();
  const formValues = watch();
  const { currencyReference } = formValues;

  return (
    <div className={clsx('relative flex flex-col py-12', isHaveBorder && 'border-b border-neutral-40', className)}>
      <div className='flex items-center gap-8'>
        {value > 9999 ? (
          <CustomTooltip title={SimpleFormatNumber(value || 0, currencyReference === 'JPY')}>
            <strong className={clsx('font-bold text-blue_grey-90', size === 'lg' ? 'text-20' : 'text-16')}>{nFormatter(value, 1)}</strong>
          </CustomTooltip>
        ) : (
          <strong className={clsx('font-bold text-blue_grey-90', size === 'lg' ? 'text-20' : 'text-16')}>
            {SimpleFormatNumber(value || 0, currencyReference === 'JPY')}
          </strong>
        )}
        {/* Allow value of 0 also */}
        {percent !== undefined && (
          <div
            className={clsx(
              'px-6 py-1 text-12 font-medium rounded-full',
              percentType === StatPercentType.PRIMARY && 'bg-[#E5F3F9] text-[#269ECC]',
              percentType === StatPercentType.DANGER && 'bg-[#FCE5E9] text-[#E72547]',
              percentType === StatPercentType.SUCCESS && 'bg-[#E3F5F1] text-[#16AD89]'
            )}
          >
            {percent}%
          </div>
        )}
      </div>
      <p className='text-12 text-neutral-60'>{label}</p>
    </div>
  );
};
