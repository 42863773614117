import { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress } from '@mui/material';
import format from 'date-fns/format';

import Modal from 'src/presentations/components/molecules/modal';

import { beautifyEnum, capitalizeName } from 'src/utils/utility';
import { useInvoiceFileProcessing } from 'src/use-cases/invoice/useInvoices';
import { InvoiceFileProcessingStatusDto } from 'src/generated/services/TFinancialApi';
import { UploadStatusItem } from './UploadStatusItem';
import TransportMediumIcon from '../card/TransportMediumIcon';
import { INotificationDetailProps } from './types';
import { useNotificationSelector } from './NotificationSelector';

const StyledModal = styled(Modal)({
  '& .MuiDialog-container': {
    zIndex: 990,
  },
  '& .MuiDialog-paper': {
    maxWidth: '1000px',
    borderRadius: '16px',
    zIndex: 999,

    '& .MuiDialogContent-root': {
      padding: '20px 0',
    },
  },
});

export const NotificationDetail: FC<INotificationDetailProps> = ({ errorInvoiceFileProcessingStatuses }) => {
  const { selectedDetail, setSelectedDetail } = useNotificationSelector();

  const { data, isLoading } = useInvoiceFileProcessing(selectedDetail?.batchId, !!selectedDetail);

  const invoiceProcessed = useMemo(
    () =>
      selectedDetail?.notificationStatus === 'EMAIL_RECEIVED'
        ? selectedDetail?.processingInvoices || 0
        : (selectedDetail?.flaggedInvoices || 0) +
          (selectedDetail?.errorInvoices || 0) +
          (selectedDetail?.rejectedInvoices || 0) +
          (selectedDetail?.approvedInvoices || 0),
    [selectedDetail]
  );

  if (!selectedDetail) {
    return null;
  }

  const onCloseModal = () => {
    setSelectedDetail(null);
  };

  return (
    <StyledModal open={!!selectedDetail} onClose={onCloseModal} fullWidth maxWidth='md'>
      <div>
        <div className='px-24 pb-24 border-b border-neutral-20'>
          {selectedDetail?.notificationStatus === 'EMAIL_RECEIVED' ? (
            <div className='w-[162px] h-[24px] rounded-[3px] bg-[#F5F6FA] my-4' />
          ) : (
            <div className='flex items-center gap-10'>
              <div className='flex items-center justify-center'>
                {selectedDetail?.modalities?.map((item, index) => (
                  <div key={`notif-${selectedDetail?.notificationId}-modality-${item}`} className={index > 0 ? '-ml-10' : ''}>
                    <TransportMediumIcon medium={item} iconColor='#9F9F9F' />
                  </div>
                ))}
              </div>
              <h2 className='text-20 font-bold text-blue_grey-80 max-lg:text-18'>
                {selectedDetail?.serviceProviderCodes?.length > 1 ? (
                  <>{selectedDetail?.serviceProviderCodes?.length} SERVICE PROVIDERS</>
                ) : (
                  <>{beautifyEnum(selectedDetail?.serviceProviderCodes[0])?.toUpperCase()}</>
                )}
              </h2>
              <div className='w-4 h-4 rounded-full bg-[#D9D9D9]' />
              <div className='uppercase text-neutral-60 text-16'>{selectedDetail?.modalities?.join(' & ')}</div>
            </div>
          )}
          <h3 className='text-18 font-bold mt-12'>Current Invoice Statuses in System: {invoiceProcessed} Items</h3>
          <p className='text-14 text-blue_grey-80 font-medium mt-5'>
            {selectedDetail?.notificationSourceType === 'EMAIL' ? 'Email received on' : 'Uploaded on'}{' '}
            {format(new Date(selectedDetail?.uploadedOn), "dd-MMM-yyyy 'at' hh:mm a")} {selectedDetail?.notificationSourceType === 'EMAIL' ? 'from' : 'by'}{' '}
            <strong className='font-medium text-neutral-80'>
              {selectedDetail?.notificationSourceType === 'EMAIL'
                ? selectedDetail?.uploadedBy // Show name as is for EMAIL
                : capitalizeName(selectedDetail?.uploadedBy)}{' '}
              {/* Apply the utility for other cases */}
            </strong>
          </p>
        </div>
        <div className='p-24'>
          {isLoading ? (
            <CircularProgress size={24} color='primary' />
          ) : (
            <div className='flex flex-col gap-10'>
              {data?.map((item: InvoiceFileProcessingStatusDto) => (
                <UploadStatusItem data={item} errorInvoiceFileProcessingStatuses={errorInvoiceFileProcessingStatuses} />
              ))}
            </div>
          )}
        </div>
        <div className='pt-24 px-24 border-t border-neutral-20 flex items-center justify-end'>
          <Button
            type='button'
            variant='outlined'
            color='inherit'
            // disabled={!batchId || batchId === ''}
            onClick={() => setSelectedDetail(null)}
            sx={{ textTransform: 'capitalize', borderColor: '#dfdfdf' }}
          >
            Close
          </Button>
        </div>
      </div>
    </StyledModal>
  );
};
