import Chip from '@mui/material/Chip';

interface StatusChipProps {
  status: string;
  size?: 'small' | 'medium';
}

const StatusChip: React.FC<StatusChipProps> = ({ status, size = 'medium' }) => {
  let chipStyle = {};
  let label = '';

  switch (status) {
    case 'APPROVED':
      chipStyle = {
        background: 'rgba(0, 153, 43, 0.12)',
        border: '1px solid #00992B',
        borderRadius: '20px',
        color: '#00992B',
      };
      label = 'Ready for Payment';
      break;

    case 'TO_APPROVE':
      chipStyle = {
        background: 'rgba(38, 158, 204, 0.2)',
        border: '1px solid #269ECC',
        borderRadius: '20px',
        color: '#1D7699',
      };
      label = 'To Approve';
      break;

    case 'TO_REJECT':
      chipStyle = {
        background: '#DFDFDF',
        border: '1px solid #475B69',
        borderRadius: '20px',
        color: '#475B69',
      };
      label = 'To Reject';
      break;

    case 'REJECTED':
      chipStyle = {
        background: 'rgba(231, 37, 71, 0.20)',
        border: '1px solid #E72547',
        borderRadius: '20px',
        color: '#E72547',
      };
      label = 'Rejected';
      break;

    case 'PENDING_DOCUMENTS':
    case 'PENDING_DOCUMENT':
      chipStyle = {
        background: 'rgba(253, 202, 64, 0.20)',
        border: '1px solid #FDCA40',
        borderRadius: '20px',
        color: '#E3B539',
      };
      label = 'Pending Document';
      break;

    case 'FLAGGED':
    case 'NO_CONTRACT_FOUND':
      chipStyle = {
        background: 'rgba(247, 152, 36, 0.2)',
        border: '1px solid #F79824',
        borderRadius: '20px',
        color: '#E58D21',
      };
      label = 'Flagged';
      break;

    case 'NOT_AN_INVOICE':
      chipStyle = {
        background: 'rgba(105, 123, 212, 0.12)',
        border: '1px solid #697BD4',
        borderRadius: '20px',
        color: '#697BD4',
      };
      label = 'Not an Invoice';
      break;

    case 'MISSING_REQUIRED_FIELD':
      chipStyle = {
        background: 'rgb(255, 255, 255, 1)',
        border: '1px solid #E72547',
        borderRadius: '20px',
        color: '#E72547',
      };
      label = 'Missing Required Fields';
      break;

    default:
      break;
  }

  return <Chip label={label} style={chipStyle} size={size} />;
};

export default StatusChip;
